// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    access_token: null,
    name: null,
    email: null,
    role: null,
    driver: null,
  },
  mutations: {
    UPDATE_TOKEN(state, payload) {
      state.access_token = payload
    },
    UPDATE_USER(state, obj) {
      Object.assign(state, obj)
    },
    CLEAR(state) {
      state.access_token = null
      state.name = null
      state.email = null
      state.role = null
      state.driver = null
      localStorage.removeItem('accessToken')
      sessionStorage.removeItem('accessToken')
    },
  },
  actions: {
    FETCH({ commit }) {
      return new Promise(resolve => {
        axios.get('auth/me')
          .then(response => {
            commit('UPDATE_USER', response.data.data)
            resolve()
          }).catch(resolve)
      })
    },
    SESSION_INIT({ commit }) {
      return new Promise(resolve => {
        if (sessionStorage.getItem('accessToken')) {
          commit('UPDATE_TOKEN', sessionStorage.getItem('accessToken'))
        } else if (localStorage.getItem('accessToken')) {
          commit('UPDATE_TOKEN', localStorage.getItem('accessToken'))
        }
        resolve()
      })
    },
  },
  getters: {
    role(state) {
      return state.role
    },
    driver_id(state) {
      return state.driver?.id
    },
  },
}
